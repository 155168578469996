import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, UploadField } from '@/components/Form';
import { windowReplaceOriginUrl } from '@/utils/url';
import { hasFirstItem, isValidFile } from '@/utils/validate';

import { useInformation } from '../../api/getInformation';
import { UploadFilesDTO, useUploadFiles } from '../../api/uploadFiles';

const coDocumentationSchema = z.object({
  identityId: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  proofOfAddress: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  bankStatement: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  eps: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  pensionFund: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  severanceFund: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb'),
  workPermit: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
  fpv: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
  afc: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
  hic: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
  edc: z
    .instanceof(FileList)
    .refine((fileList) => hasFirstItem(fileList), 'Required')
    .refine(isValidFile, 'File should be less than 20mb')
    .optional(),
});

export type TCoDocumentationSchema = z.infer<typeof coDocumentationSchema>;

const documentationDTO = (values: TCoDocumentationSchema): UploadFilesDTO => {
  return {
    data: {
      identityId: values.identityId.item(0),
      bankStatement: values.bankStatement.item(0),
      proofOfAddress: values.proofOfAddress.item(0),
      workPermit: values.workPermit?.item(0),
      eps: values.eps.item(0),
      pensionFund: values.pensionFund.item(0),
      severanceFund: values.severanceFund.item(0),
      afc: values.afc?.item(0),
      edc: values.edc?.item(0),
      fpv: values.fpv?.item(0),
      hic: values.hic?.item(0),
    },
  };
};

export const CoDocumentationForm = () => {
  const uploadFilesMutation = useUploadFiles();
  const onboardingInformationQuery = useInformation();

  const handleOnSubmit = async (values: TCoDocumentationSchema) => {
    await uploadFilesMutation.mutateAsync(documentationDTO(values), {
      onSettled: (data) => {
        if (data) windowReplaceOriginUrl();
      },
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const onboardingInformation = onboardingInformationQuery.data?.data;
  const nationality = onboardingInformation?.payee.nationality;
  const offerCountryCode = onboardingInformation?.config.offerCountryCode;
  const voluntaryContributions = onboardingInformation?.personalIds.voluntaryContributions;
  const needsPermit = nationality !== offerCountryCode;
  const needsVoluntaryContribution = (name: string) => voluntaryContributions?.includes(name);

  return (
    <div>
      <Form<TCoDocumentationSchema, typeof coDocumentationSchema>
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={coDocumentationSchema}
        className="space-y-8 divide-y divide-gray-200"
      >
        {({ register, formState }) => (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Documentation</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please upload the required files. Max 20MB per file.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <UploadField
                    label="Document ID"
                    error={formState.errors['identityId']}
                    registration={register('identityId')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
                <div className="sm:col-span-3">
                  <UploadField
                    label="Proof of address"
                    error={formState.errors['proofOfAddress']}
                    registration={register('proofOfAddress')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
                <div className="sm:col-span-3">
                  <UploadField
                    label="Bank statement"
                    error={formState.errors['bankStatement']}
                    registration={register('bankStatement')}
                    accept=".jpg, .jpeg, .png, .pdf"
                    helperText="account number + relevant information must be visible"
                  />
                </div>
                <div className="sm:col-span-3">
                  <UploadField
                    label="EPS affiliation certificate"
                    error={formState.errors['eps']}
                    registration={register('eps')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
                <div className="sm:col-span-3">
                  <UploadField
                    label="Pension fund affiliation certificate"
                    error={formState.errors['pensionFund']}
                    registration={register('pensionFund')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
                <div className="sm:col-span-3">
                  <UploadField
                    label="Severance fund affiliation certificate"
                    error={formState.errors['severanceFund']}
                    registration={register('severanceFund')}
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                </div>
                {needsPermit && (
                  <div className="sm:col-span-3">
                    <UploadField
                      label="Work permit"
                      error={formState.errors['workPermit']}
                      registration={register('workPermit')}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                )}
                {needsVoluntaryContribution('FPV') && (
                  <div className="sm:col-span-3">
                    <UploadField
                      label="FPV affiliation certificate"
                      error={formState.errors['fpv']}
                      registration={register('fpv')}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                )}
                {needsVoluntaryContribution('AFC') && (
                  <div className="sm:col-span-3">
                    <UploadField
                      label="AFC account certificate"
                      error={formState.errors['afc']}
                      registration={register('afc')}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                )}
                {needsVoluntaryContribution('HIC') && (
                  <div className="sm:col-span-3">
                    <UploadField
                      label="Public accountant certificate accrediting economic dependents"
                      error={formState.errors['hic']}
                      registration={register('hic')}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                )}
                {needsVoluntaryContribution('EDC') && (
                  <div className="sm:col-span-3">
                    <UploadField
                      label="Prepaid medicine certificate or health insurance payments for the previous year"
                      error={formState.errors['edc']}
                      registration={register('edc')}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={uploadFilesMutation.isLoading}
                  disabled={uploadFilesMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
