import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { BetaCountrySelect } from '@/features/misc';
import { useAuth } from '@/lib/auth';

import { BankDetailsDTO, useBankDetails } from '../../api/bankDetails';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const addressSchema = z.object({
  street: z.string().min(1, 'Required'),
  city: z.string().min(1, 'Required'),
  state: z.string().min(1, 'Required'),
  postalCode: z.string().min(1, 'Required'),
  countryCode: z.string().min(1, 'Required'),
});

const globalBankingSchema = z.object({
  ...addressSchema.shape,
});

export type TGlobalBankingSchema = z.infer<typeof globalBankingSchema>;

const bankDetailsDTO = (
  values: TGlobalBankingSchema,
  bankDetails: Pick<BankDetailsDTO['bankDetails'], 'countryCode' | 'currencyCode'>
): BankDetailsDTO => {
  return {
    bankAddress: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.countryCode,
    },
    bankDetails,
  };
};

export const GlobalBankingForm = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const onboardingInformationQuery = useInformation();
  const bankDetailsMutation = useBankDetails();

  const countryCode = user?.data.config.offerCountryCode || '';
  const currencyCode = user?.data.config.offerCurrencyCode || '';

  const handleOnSubmit = async (values: TGlobalBankingSchema) => {
    await bankDetailsMutation.mutateAsync(
      bankDetailsDTO(values, {
        countryCode,
        currencyCode,
      }),
      {
        onSettled: (data) => {
          if (data) navigate('/onboarding/documentation');
        },
      }
    );
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TGlobalBankingSchema> = {
    ...onboardingInformation?.bankDetails,
    ...onboardingInformation?.bankAddress,
  };

  return (
    <div>
      <Form<TGlobalBankingSchema, typeof globalBankingSchema>
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={globalBankingSchema}
        className="space-y-8 divide-y divide-gray-200"
        options={{ defaultValues, shouldUnregister: true }}
      >
        {({ formState, register, resetField, watch }) => (
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Bank Account Address
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please enter the address tied to your bank account.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <State countryCode={watch('countryCode')} />
                </div>
                <div className="sm:col-span-1">
                  <InputField
                    label="Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <BetaCountrySelect
                    label="Country"
                    error={formState.errors['countryCode']}
                    registration={register('countryCode', {
                      onChange: () => {
                        resetField('state', { defaultValue: '' });
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={bankDetailsMutation.isLoading}
                  disabled={bankDetailsMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
