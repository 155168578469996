import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, SelectField } from '@/components/Form';
import { refineAccount } from '@/features/banking';
import { ColombiaBankSelect, colombiaSwiftBicCode } from '@/features/countries';
import { BetaCountrySelect } from '@/features/misc';
import { queryClient } from '@/lib/react-query';
import { parsedCountryCode } from '@/utils/format';
import { isValidBic, isValidInt } from '@/utils/validate';

import { BankDetailsDTO, useBankDetails } from '../../api/bankDetails';
import { useInformation } from '../../api/getInformation';
import { State } from '../State';

const detailsSchema = z.object({
  name: z.string().trim().min(1, 'Required').optional(),
  account: z.string().trim().min(1, 'Required').optional(),
  type: z.string().trim().min(1, 'Required').optional(),
  currencyCode: z.string().trim().min(1, 'Required'),
  countryCode: z.string().trim().min(1, 'Required').optional(),
  swiftBic: z.string().trim().min(1, 'Required').optional(),
  accountLocation: z.string().trim().min(1, 'Required').optional(),
  routingNumber: z
    .string()
    .trim()
    .length(9, 'Routing number should be 9 digits')
    .refine(isValidInt, { message: 'Routing number should be numeric' })
    .optional(),
  clabe: z
    .string()
    .trim()
    .length(18, 'CLABE should be 18 digits')
    .refine(isValidInt, { message: 'CLABE should be numeric' })
    .optional(),
  institutionNumber: z
    .string()
    .trim()
    .min(1, 'Required')
    .length(3, 'Institution number should be 3 digits')
    .refine(isValidInt)
    .optional(),
  transitCode: z
    .string()
    .trim()
    .min(1, 'Required')
    .length(5, 'Transit number should be 5 digits')
    .refine(isValidInt)
    .optional(),
});

const addressSchema = z.object({
  street: z.string().min(1, 'Required'),
  city: z.string().min(1, 'Required'),
  state: z.string().min(1, 'Required'),
  postalCode: z.string().min(1, 'Required'),
  addressCountryCode: z.string().min(1, 'Required'),
});

const defaultBankingSchema = z
  .object({
    ...detailsSchema.shape,
    ...addressSchema.shape,
  })
  .refine(
    ({ currencyCode, account = '', accountLocation }) =>
      refineAccount({ currencyCode, account, accountLocation }),
    {
      path: ['account'],
      message: 'The account number is invalid',
    }
  )
  .refine(
    ({ swiftBic }) => {
      if (swiftBic) return isValidBic(swiftBic);
      return true;
    },
    {
      path: ['swiftBic'],
      message: 'The SWIFT / BIC code is invalid',
    }
  );

export type TDefaultBankingSchema = z.infer<typeof defaultBankingSchema>;

const bankDetailsDTO = (values: TDefaultBankingSchema): BankDetailsDTO => {
  return {
    bankDetails: {
      accountLocation: values.accountLocation,
      name: values.name,
      account: values.account,
      type: values.type,
      countryCode: values.countryCode || parsedCountryCode(values.currencyCode),
      currencyCode: values.currencyCode,
      clabe: values.clabe,
      institutionNumber: values.institutionNumber,
      transitCode: values.transitCode,
      swiftBic: values.swiftBic,
      routingNumber: values.routingNumber,
    },
    bankAddress: {
      street: values.street,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.addressCountryCode,
    },
  };
};

export const DefaultBankingForm = () => {
  const navigate = useNavigate();

  const onboardingInformationQuery = useInformation();
  const bankDetailsMutation = useBankDetails();

  const handleOnSubmit = async (values: TDefaultBankingSchema) => {
    await bankDetailsMutation.mutateAsync(bankDetailsDTO({ ...values }), {
      onSettled: (data) => {
        if (data) {
          queryClient.invalidateQueries('information');
          navigate('/onboarding/documentation');
        }
      },
    });
  };

  if (onboardingInformationQuery.isLoading)
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const onboardingInformation = onboardingInformationQuery.data?.data;

  const defaultValues: Partial<TDefaultBankingSchema> = {
    ...onboardingInformation?.bankAddress,
    ...onboardingInformation?.bankDetails,
    addressCountryCode: onboardingInformation?.bankAddress.countryCode,
    countryCode:
      onboardingInformation?.bankDetails.countryCode ||
      parsedCountryCode(onboardingInformation?.bankDetails.currencyCode || ''),
  };

  return (
    <div>
      <Form<TDefaultBankingSchema, typeof defaultBankingSchema>
        id="default-banking-schema"
        onSubmit={async (payee) => {
          handleOnSubmit(payee);
        }}
        schema={defaultBankingSchema}
        className="space-y-8 divide-y divide-gray-200"
        options={{ defaultValues, shouldUnregister: true }}
      >
        {({ formState, register, resetField, watch, setValue }) => {
          const currencyCode = watch('currencyCode');
          const countryCode = watch('countryCode');
          const accountType = watch('type');
          const accountLocation = watch('accountLocation');
          const bankName = watch('name');

          const isMXN = currencyCode === 'MXN';
          const isEUR = currencyCode === 'EUR';
          const isUSD = currencyCode === 'USD';
          const isCOP = currencyCode === 'COP';
          const isCAD = currencyCode === 'CAD';

          const isColombia = countryCode === 'CO';
          const bankHasSwiftCode = Boolean(colombiaSwiftBicCode(bankName));

          return (
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Bank Account Details
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please enter the account where you’d like to receive your payments.
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-2">
                    <SelectField
                      label="Bank account currency"
                      error={formState.errors['currencyCode']}
                      registration={register('currencyCode')}
                      options={[
                        { label: '', value: '' },
                        { label: 'EUR', value: 'EUR' },
                        { label: 'CAD', value: 'CAD' },
                        { label: 'COP', value: 'COP' },
                        { label: 'MXN', value: 'MXN' },
                        { label: 'USD', value: 'USD' },
                      ]}
                    />
                  </div>
                  {isMXN && (
                    <>
                      <div className="sm:col-span-2">
                        <InputField
                          label="Financial institution"
                          error={formState.errors['name']}
                          helperText="ex., Chase, TD Bank, HSBC, Bancolombia, CITI"
                          registration={register('name')}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <InputField
                          label="Account number"
                          error={formState.errors['account']}
                          registration={register('account')}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <InputField
                          label="CLABE"
                          error={formState.errors['clabe']}
                          registration={register('clabe')}
                        />
                      </div>
                    </>
                  )}
                  {isCAD && (
                    <>
                      <div className="sm:col-span-2">
                        <InputField
                          label="Institution Number"
                          error={formState.errors['institutionNumber']}
                          registration={register('institutionNumber')}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <SelectField
                          label="Account type"
                          error={formState.errors['type']}
                          registration={register('type')}
                          options={[
                            { label: '', value: '' },
                            { label: 'Checking', value: 'checking' },
                            { label: 'Savings', value: 'saving' },
                          ]}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <InputField
                          label="Account number"
                          error={formState.errors['account']}
                          registration={register('account')}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <InputField
                          label="Transit number"
                          error={formState.errors['transitCode']}
                          registration={register('transitCode')}
                        />
                      </div>
                    </>
                  )}
                  {isCOP && (
                    <>
                      <div className="sm:col-span-2">
                        <ColombiaBankSelect>
                          <SelectField
                            label="Financial institution"
                            error={formState.errors['name']}
                            registration={register('name', {
                              onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                                setValue(
                                  'swiftBic',
                                  colombiaSwiftBicCode(event.currentTarget.value) || undefined,
                                  {
                                    shouldValidate: true,
                                  }
                                );
                              },
                            })}
                            options={[{ label: '', value: '' }]}
                          />
                        </ColombiaBankSelect>
                      </div>
                      {bankHasSwiftCode && (
                        <div className="sm:col-span-2">
                          <InputField
                            label="SWIFT / BIC code"
                            error={formState.errors['swiftBic']}
                            registration={register('swiftBic')}
                            helperText={
                              <p>
                                Please confirm that your
                                <span className="font-medium"> SWIFT / BIC</span> code is accurate
                                before clicking
                                <span className="font-medium"> Submit</span>
                              </p>
                            }
                          />
                        </div>
                      )}
                      <div className="sm:col-span-2">
                        <SelectField
                          label="Account type"
                          error={formState.errors['type']}
                          registration={register('type')}
                          options={[
                            { label: '', value: '' },
                            { label: 'Current', value: 'current' },
                            { label: 'Savings', value: 'savings' },
                          ]}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <InputField
                          label="Transit and account number"
                          error={formState.errors['account']}
                          registration={register('account')}
                        />
                      </div>
                    </>
                  )}
                  {isEUR && (
                    <>
                      <div className="sm:col-span-2">
                        <SelectField
                          label="Account type"
                          error={formState.errors['type']}
                          registration={register('type')}
                          options={[
                            { label: '', value: '' },
                            { label: 'Inside Europe', value: 'inside' },
                            { label: 'Outside Europe', value: 'outside' },
                          ]}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <BetaCountrySelect
                          label="Bank account country"
                          error={formState.errors['countryCode']}
                          registration={register('countryCode')}
                        />
                      </div>
                      {accountType === 'inside' && (
                        <div className="sm:col-span-2">
                          <InputField
                            label="IBAN"
                            error={formState.errors['account']}
                            registration={register('account')}
                          />
                        </div>
                      )}
                      {accountType === 'outside' && (
                        <>
                          <div className="sm:col-span-2">
                            {isColombia ? (
                              <ColombiaBankSelect>
                                <SelectField
                                  label="Financial institution"
                                  error={formState.errors['name']}
                                  registration={register('name', {
                                    onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                                      setValue(
                                        'swiftBic',
                                        colombiaSwiftBicCode(event.currentTarget.value),
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    },
                                  })}
                                  options={[{ label: '', value: '' }]}
                                />
                              </ColombiaBankSelect>
                            ) : (
                              <InputField
                                label="Financial institution"
                                error={formState.errors['name']}
                                helperText="ex., Chase, TD Bank, HSBC, Bancolombia, CITI"
                                registration={register('name')}
                              />
                            )}
                          </div>
                          <div className="sm:col-span-2">
                            <InputField
                              label="IBAN / Account number"
                              error={formState.errors['account']}
                              registration={register('account')}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <InputField
                              label="SWIFT / BIC code"
                              error={formState.errors['swiftBic']}
                              registration={register('swiftBic')}
                              helperText={
                                <p>
                                  Please confirm that your
                                  <span className="font-medium"> SWIFT / BIC</span> code is accurate
                                  before clicking
                                  <span className="font-medium"> Submit</span>
                                </p>
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {isUSD && (
                    <>
                      <div className="sm:col-span-2">
                        <SelectField
                          label="Account location"
                          error={formState.errors['accountLocation']}
                          registration={register('accountLocation')}
                          options={[
                            { label: '', value: '' },
                            { label: 'Local US Bank Account', value: 'local' },
                            { label: 'Swift Account', value: 'swift' },
                          ]}
                        />
                      </div>
                      {accountLocation === 'local' && (
                        <>
                          <div className="sm:col-span-2">
                            <SelectField
                              label="Account type"
                              error={formState.errors['type']}
                              registration={register('type')}
                              options={[
                                { label: '', value: '' },
                                { label: 'Checking', value: 'checking' },
                                { label: 'Savings', value: 'savings' },
                              ]}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <InputField
                              label="Account number"
                              error={formState.errors['account']}
                              registration={register('account')}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <InputField
                              label="Route number"
                              error={formState.errors['routingNumber']}
                              registration={register('routingNumber')}
                            />
                          </div>
                        </>
                      )}
                      {accountLocation === 'swift' && (
                        <>
                          <div className="sm:col-span-2">
                            <BetaCountrySelect
                              omitCountries={['US']}
                              label="Bank account country"
                              error={formState.errors['countryCode']}
                              registration={register('countryCode')}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            {isColombia ? (
                              <ColombiaBankSelect>
                                <SelectField
                                  label="Financial institution"
                                  error={formState.errors['name']}
                                  registration={register('name', {
                                    onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                                      setValue(
                                        'swiftBic',
                                        colombiaSwiftBicCode(event.currentTarget.value),
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    },
                                  })}
                                  options={[{ label: '', value: '' }]}
                                />
                              </ColombiaBankSelect>
                            ) : (
                              <InputField
                                label="Financial institution"
                                error={formState.errors['name']}
                                helperText="ex., Chase, TD Bank, HSBC, Bancolombia, CITI"
                                registration={register('name')}
                              />
                            )}
                          </div>
                          <div className="sm:col-span-2">
                            <InputField
                              label="SWIFT / BIC code"
                              error={formState.errors['swiftBic']}
                              registration={register('swiftBic')}
                              helperText={
                                <p>
                                  Please confirm that your
                                  <span className="font-medium"> SWIFT / BIC</span> code is accurate
                                  before clicking
                                  <span className="font-medium"> Submit</span>
                                </p>
                              }
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <InputField
                              label="IBAN / Account number"
                              error={formState.errors['account']}
                              registration={register('account')}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="pt-8">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Bank Account Address
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please enter the address tied to your bank account.
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <InputField
                      label="Street"
                      error={formState.errors['street']}
                      registration={register('street')}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <InputField
                      label="City"
                      error={formState.errors['city']}
                      registration={register('city')}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <State countryCode={watch('addressCountryCode')} />
                  </div>
                  <div className="sm:col-span-1">
                    <InputField
                      label="Postal code"
                      error={formState.errors['postalCode']}
                      registration={register('postalCode')}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <BetaCountrySelect
                      label="Country"
                      error={formState.errors['addressCountryCode']}
                      registration={register('addressCountryCode', {
                        onChange: () => {
                          resetField('state', { defaultValue: '' });
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end">
                  <Button
                    isLoading={bankDetailsMutation.isLoading}
                    disabled={bankDetailsMutation.isLoading}
                    type="submit"
                    size="lg"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};
