import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
  cloneElement,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Button } from '../Button';

type ModalDialogProps = {
  confirmButton: JSX.Element;
  isDone: boolean;
  title: ReactNode;
  triggerButton: JSX.Element;
  description?: ReactNode;
  icon?: JSX.Element;
  showDismiss?: boolean;
  hasCancelButton?: boolean;
};

export const ModalDialog = ({
  children,
  confirmButton,
  description,
  icon,
  isDone,
  title,
  triggerButton,
  showDismiss = true,
  hasCancelButton = true,
}: PropsWithChildren<ModalDialogProps>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const confirmationButtonRef = useRef<HTMLButtonElement>(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (isDone) closeModal();
  }, [isDone]);

  return (
    <>
      {cloneElement(triggerButton, { onClick: openModal })}
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeModal}
          initialFocus={confirmationButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-ebony-clay-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform space-y-4 overflow-hidden rounded-3xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:space-y-4 sm:p-6">
                  {showDismiss && (
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-full bg-white text-ebony-clay-400 hover:text-ebony-clay-500 focus:outline-none focus:ring-2 focus:ring-dodger-blue-500 focus:ring-offset-2"
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                  <div className="sm:text-start text-center">
                    <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-2">
                      {icon && (
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-froly-100 sm:mx-0 sm:h-10 sm:w-10">
                          {cloneElement(icon, {
                            'aria-hidden': 'true',
                          })}
                        </div>
                      )}
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-ebony-clay-900"
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                    {description && (
                      <div className="mt-2">
                        <p className="text-sm text-ebony-clay-500">{description}</p>
                      </div>
                    )}
                  </div>
                  {children && <div className="py-1 sm:py-2">{children}</div>}
                  <div className="flex flex-col gap-3 sm:flex-row-reverse">
                    {cloneElement(confirmButton, {
                      ref: confirmationButtonRef,
                    })}
                    {hasCancelButton && (
                      <Button
                        onClick={closeModal}
                        variant="alternative"
                        className="w-full sm:w-auto"
                        disabled={isDone}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
